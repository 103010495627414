<template>
  <div class="container">
    <div class="top_container">
      <a-icon @click="goProductList" class="back_btn" type="left"/>
      <span>登陆</span>
    </div>
    <a-form-model ref="form" :model="form" :rules="rules">
      <div class="form_container mt10">
        <a-form-model-item label="手机号" prop="mobile">
          <a-input v-model="form.mobile"/>
        </a-form-model-item>
        <a-form-model-item label="密码" prop="pwd">
          <a-input type="password"  v-model="form.pwd"/>
        </a-form-model-item>
        <a-form-model-item label="公司名称" prop="user_list">
          <a-cascader :fieldNames="{label: 'name', value: 'id', children: 'children'}" :options="user_list"
                      placeholder="" v-model="form.user_list"/>
        </a-form-model-item>
      </div>
    </a-form-model>
    <div style="padding: 20px;text-align: center">
      <a-button type="primary" :loading="loading" :disabled="loading" @click="onSubmit">提交</a-button>
    </div>
  </div>
</template>
<script>
import request from '@/utils/request';
import enums from "../utils/enums";
import cookies from 'vue-cookies';
export default {
  data() {
    return {
      enums,
      form: {},
      rules: {
        pwd: [{required: true, message: '请输入密码'}],
        mobile: [
          {required: true, message: '请输入电话'},
        ] ,
        user_list: [{
          type: 'array',
          required: false,
          message: '请故障类别',
          trigger: 'change'
        }]      
      },
      loading: false,
      user_list: []
    }
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          data.channel=2     
          this.loading = true
          if(data.user_list!=null){
            data.uid=data.user_list[0]
          }
          request({
            url: 'user/login_by_pwd',
            data: data
          }).then(r => {
            if (r.data.success) {
              if(r.data.data.length>1){
                  this.user_list=r.data.data;
                  alert('请选择公司！')
              }else{
                var time1=60*60*20
                cookies.set('name',r.data.data.name,time1)
                cookies.set('mobile',r.data.data.mobile,time1)
                cookies.set('customer_id',r.data.data.customer_id,time1)
                cookies.set('token',r.data.data.wechat_token,time1)
                cookies.set('location',r.data.data.location,time1)
                cookies.set('daqu',r.data.data.daqu,time1)
                this.$router.replace({ path: '/single', query: {
                  enterpriseId: enums.STATE.ENTERPRISE_ID.v
                }})
                alert('登陆成功！')
              }
              
            } else if (r.data.error_code === enums.STATE.FEEDBACK_REPORT_FAULT_MOBILE.v) {
              alert(r.data.msg)
            } else {
              alert('网络异常,请稍后重试')
            }
          }).finally(() => {
            this.loading = false
          })
        }
      });
    },
    goProductList () {
      this.$router.replace({ path: '/single', query: {
        enterpriseId: this.enterprise_id
      }})
    }
  }
}
</script>
<style lang="less" scoped>
@import "../assets/css/form.less";

.ant-form-item {
  align-items: center;
}

/deep/ .ant-form-item-label {
  label::before {
    display: inline-block;
    margin-right: 11px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '';
  }
  .ant-form-item-required::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.container {
  padding: 8px;
  padding-top: 58px;

  .form_container {
    background-color: #fff;
    border-radius: 10px;
    .ant-form-item:last-child {
      border-bottom: none;
    }
  }

  .mt10 {
    margin-top: 10px;
  }

  .button-view {
    padding: 0 8px;
    display: flex;
    justify-content: flex-end;
  }

  .form-button {
    display: flex;
    align-items: center;
    margin-left: 5px;
    background: #3573e0;
    color: #fff;
    border-radius: 3px;
    padding: 2px 5px;
    font-size: 14px;
  }
}
</style>
